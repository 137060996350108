/* Styling for the navigation section */
.navsection {
  display: flex;
  background-color: rgb(174, 148, 1); /* Background color */
  padding: 1rem 1.5rem; /* Padding */
}

/* Styling for the logo section */
.logoSection {
  display: flex;
  flex: 20%; /* 20% of the container */
}

/* Styling for the logo */
.logo {
  width: 100%; /* Full width */
}

/* Hide menu icons */
.menuIcons {
  display: none;
}

/* Styling for the menu section */
.menuSection {
  display: flex;
  flex: 90%; /* 90% of the container */
  justify-content: end; /* Align items to the end */
}

/* Styling for the menu items section */
.menuItemsSection {
  display: flex;
  gap: 20px; /* Gap between menu items */
  justify-content: center; /* Center align items */
}

/* Styling for the menu links */
.menuLinks {
  text-decoration: none; /* No underline */
  color: #fff; /* Text color */
  font-family: "Roboto", sans-serif; /* Font family */
  font-weight: 600; /* Font weight */
  font-style: normal; /* Normal font style */
  display: flex; /* Display as flex */
  align-items: center; /* Align items vertically */
  letter-spacing: 2px; /* Letter spacing */
  transition-property: letter-spacing; /* Transition property */
  transition-duration: 0.4s; /* Transition duration */
  transition-delay: 0.1s; /* Transition delay */
}

/* Hover state for menu links */
.menuLinks:hover {
  letter-spacing: 4px; /* Increased letter spacing on hover */
  color: #0f0f0f;
}

/* Registered Events Icon on hover */
.registered-events-icon:hover {
  color: #0f0f0f /* Font size adjustment */
}
