/* Styling for the App container */
.App {
  display: flex; /* Flex container */
  flex-direction: column; /* Column direction */
  gap: 50px; /* Gap between children */
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif; /* Font family */
  -webkit-font-smoothing: antialiased; /* Font smoothing for WebKit */
  -moz-osx-font-smoothing: grayscale; /* Font smoothing for Firefox */
  margin: 0; /* Margin */
  background:
    conic-gradient(at 10% 50%, #0000 75%, #131313 0),
    conic-gradient(at 10% 50%, #0000 75%, #131313 0) calc(1 * 31px) calc(3 * 31px),
    conic-gradient(at 10% 50%, #0000 75%, #131313 0) calc(2 * 31px) calc(1 * 31px),
    conic-gradient(at 10% 50%, #0000 75%, #131313 0) calc(3 * 31px) calc(4 * 31px),
    conic-gradient(at 10% 50%, #0000 75%, #131313 0) calc(4 * 31px) calc(2 * 31px),
    conic-gradient(at 50% 10%, #0000 75%, #121212 0) 0 calc(4 * 31px),
    conic-gradient(at 50% 10%, #0000 75%, #131313 0) calc(1 * 31px) calc(2 * 31px),
    conic-gradient(at 50% 10%, #0000 75%, #131313 0) calc(2 * 31px) 0,
    conic-gradient(at 50% 10%, #0000 75%, #131313 0) calc(3 * 31px) calc(3 * 31px),
    conic-gradient(at 50% 10%, #0000 75%, #131313 0) calc(4 * 31px) calc(1 * 31px),
    #0f0f0f; /* Background gradient */

  background-size: 155px 155px; /* Background size */
}

/* Styling for links */
.links {
  text-decoration: none; /* No text decoration */
}

/* Active state for links */
.links.active {
  color: rgb(197, 168, 1); /* Active link color */
}

/* Hover effect for links */
.links:hover {
  color: rgb(197, 168, 1); /* Link color on hover */
  transition-property: color; /* Transition property */
  transition-duration: 0.3s; /* Transition duration */
  transition-delay: 0.1s; /* Transition delay */
}
