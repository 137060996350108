@media only screen and (max-width: 768px) {
  /* Body Slide adjustments */
  .body-slide {
    flex-direction: column; /* Change to column layout */
    flex-wrap: wrap; /* Wrap items */
    width: 90%; /* 90% width of the container */
    height: fit-content; /* Adjust height to fit content */
    padding: 4rem 0 1rem 1rem; /* Padding adjustments */
  }

  /* Slide Content adjustments */
  .slide-content {
    width: 100%; /* Full width */
    gap: 1rem; /* Gap between elements */
  }

  /* Title adjustments */
  .title {
    font-size: 3rem; /* Font size adjustment */
    font-weight: 800; /* Font weight adjustment */
    line-height: 3.2rem; /* Line height adjustment */
    color: rgba(231, 231, 231, 0.812); /* Text color adjustment */
    padding-right: 1rem; /* Right padding adjustment */
  }

  /* Button adjustments */
  .btn {
    position: relative; /* Relative positioning */
    top: 0;
    left: 0;
    width: 250px; /* Width of the button */
    height: 50px; /* Height of the button */
    margin: 0; /* Margin reset */
    display: flex; /* Flex display */
    justify-content: center; /* Center align content horizontally */
    align-items: center; /* Center align content vertically */
    background-color: rgba(0, 0, 0, 0); /* Transparent background */
    border: none; /* No border */
  }

  /* Button link adjustments */
  .btn a {
    position: absolute; /* Absolute positioning */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    display: flex; /* Flex display */
    font-size: 1rem; /* Font size */
    justify-content: center; /* Center align content horizontally */
    align-items: center; /* Center align content vertically */
    background: #333; /* Dark background color */
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.613); /* Box shadow */
    border-bottom: 1px solid rgba(3, 3, 3, 0.839); /* Border bottom */
    border-top: 1px solid rgba(0, 0, 0, 1); /* Border top */
    border-radius: 30px; /* Border radius */
    padding: 1px; /* Padding */
    letter-spacing: 1px; /* Letter spacing */
    text-decoration: none; /* Text decoration */
    overflow: hidden; /* Overflow */
    color: #fff; /* Text color */
    font-weight: bolder; /* Font weight */
    z-index: 1; /* Z-index */
    transition: 0.5s; /* Transition */
    backdrop-filter: blur(15px); /* Backdrop filter */
  }

  /* Button link hover adjustments */
  .btn:hover a {
    letter-spacing: 3px; /* Increased letter spacing on hover */
  }

  /* Button link hover before pseudo-element adjustments */
  .btn:hover a::after {
    transform: skewX(45deg) translate(200px); /* Skew transformation on hover */
  }

  /* Button hover before pseudo-element adjustments */
  .btn::after {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px; /* Border radius on hover */
  }

  /* Button hover after pseudo-element adjustments */
  .btn::before {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px; /* Border radius on hover */
  }

  /* Button before and after pseudo-element shared adjustments */
  .btn::before,
  .btn::after {
    background: rgb(226, 192, 0); /* Background color */
    box-shadow:
      /* Box shadow */ 0 0 5px rgb(226, 192, 0),
      /* Box shadow */ 0 0 15px rgb(226, 192, 0),
      /* Box shadow */ 0 0 30px rgb(226, 192, 0),
      /* Box shadow */ 0 0 60px rgb(226, 192, 0);
  }

  /* Button before pseudo-element adjustments */
  .btn:hover::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    width: 30px;
    height: 10px;
    background: rgb(226, 192, 0); /* Background color */
    border-radius: 10px; /* Border radius */
    transition: 0.5s; /* Transition */
    transition-delay: 0.1s; /* Transition delay */
  }

  /* Button after pseudo-element adjustments */
  .btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -5px;
    width: 30px;
    height: 10px;
    background-color: rgb(226, 192, 0); /* Background color */
    border-radius: 10px; /* Border radius */
    transition: 0.5s; /* Transition */
    transition-delay: 0.1s; /* Transition delay */
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  /* Body Slide adjustments */
  .body-slide {
    width: 75%; /* 75% width of the container */
    height: fit-content; /* Adjust height to fit content */
    padding-bottom: 2rem; /* Bottom padding adjustment */
    gap: 1rem; /* Gap between elements */
  }

  /* Slide Content adjustments */
  .slide-content {
    flex: 50%; /* Take 50% of the container width */
    gap: 1rem; /* Gap between elements */
  }

  /* Infographic adjustments */
  .infographic {
    width: 85%; /* 85% width of the infographic */
  }
}
